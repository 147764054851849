<template>
	<div class="grid">
    <div class="col-12 md:col-6" v-if="selectedStore && selectedStore.id">
			<div class="card p-fluid" bg-variant="light">
				<h5>Configurations</h5>
				<br/>
				<b-card bg-variant="light">
				<h6>
                     <span class="customer-badge status-qualified"  data-v-61bc1bb6="">Selected Store</span> : <span class="product-badge status-instock">{{ selectedStore.shop }}</span> 
				</h6>
				<br/>
				<b-container>
					<b-row>
						<b-col>
							<Button label="Enable Faster Checkout" class=" p-3 text-xl" @click="enableFasterCheckout"></button>
						</b-col>
						<b-col>
							<Button label="Disable Faster Checkout" class=" p-3 text-xl p-button-warning" @click="disableFasterCheckout"></button>
						</b-col>
						<b-col>
							<Button label="Sync Products" class="p-3 text-xl p-button-info" @click="updateProductSyncRequiredFlag"></button>
						</b-col>
					</b-row>
				</b-container>
			</b-card>
			</div>

			<div class="card p-fluid">
				<br/>
				
				<div>
					<b-card bg-variant="light">
						<b-form-group
							label-cols-lg="3"
							label="Faster Checkout Configuration"
							label-size="lg"
							label-class="font-weight-bold pt-0"
							class="mb-0"
						>
							<b-form-group
							label="Item Price Selector"
							label-for="item-price-selector"
							label-cols-sm="3"
							label-align-sm="right"
							>
							<b-form-input id="item-price-selector" v-model="fasterCheckoutConfig.itemPriceSelector"></b-form-input>
							</b-form-group>
					
							<b-form-group
							label="Title Selector"
							label-for="title-selector"
							label-cols-sm="3"
							label-align-sm="right"
							>
							<b-form-input id="title-selector" v-model="fasterCheckoutConfig.titleSelector"></b-form-input>
							</b-form-group>
					
							<b-form-group
							label="Product Image Selector"
							label-for="product-image-selector"
							label-cols-sm="3"
							label-align-sm="right"
							>
							<b-form-input id="product-image-selector" v-model="fasterCheckoutConfig.productImageSelector"></b-form-input>
							</b-form-group>
					
							<b-form-group
							label="Total Price Selector"
							label-for="total-price-selector"
							label-cols-sm="3"
							label-align-sm="right"
							>
							<b-form-input id="total-price-selector" v-model="fasterCheckoutConfig.totalPriceSelector"></b-form-input>
							</b-form-group>
					
							<b-form-checkbox switch v-model="fasterCheckoutConfig.enabled">Enabled</b-form-checkbox>

							<b-button type="submit" class="w-2 mt-6 float-right" variant="primary" @click="saveFasterCheckoutConfiguration">Save</b-button>
						</b-form-group>
						</b-card>
					</div>
			</div>
		</div>
</div>

</template>

<script>
import apiStore from "../store/index"
export default {
	data() {
		return {
			fasterCheckoutConfig: {
				itemPriceSelector: '',
				titleSelector: '',
				productImageSelector: '',
				totalPriceSelector: '',
				enabled: false
			}
		}
	},
	async mounted() {
		await this.loadFasterCheckoutConfiguration()
	},
	computed: {
		'selectedStore': function() {
			return apiStore.getters["storeinternal/getSelectedStore"]
		}
	},
	watch: {
		'selectedStore': async function(newVal, oldVal) {
			console.log('newVal', newVal)
			this.resetFasterCheckoutConfig()
			await this.loadFasterCheckoutConfiguration()
		}
	},
	methods: {
		enableFasterCheckout() {
			//ToDo: Replace with API call
		},
		disableFasterCheckout() {
			//ToDo: Replace with API call
		},
		resetFasterCheckoutConfig() 
		{
			this.fasterCheckoutConfig =  {
				itemPriceSelector: '',
				titleSelector: '',
				productImageSelector: '',
				totalPriceSelector: '',
				enabled: false
			}
		},
		async loadFasterCheckoutConfiguration() {
			var response = await apiStore.dispatch("storeinternal/getFasterCheckoutConfiguration", {id: this.selectedStore.id});
			if(response !== undefined && response != '') {
				this.fasterCheckoutConfig = response
			}
		},
		async saveFasterCheckoutConfiguration() {
			try{
				var payload = {...this.fasterCheckoutConfig, installationId: this.selectedStore.id}
				var response = await apiStore.dispatch("storeinternal/saveFasterCheckoutConfiguration", payload);
				if(response !== undefined) {
					this.$wkToast('Faster checkout settings saved successfully')
				}
			}
			catch(exception) {
				this.$wkToast('Error occurred while saving faster checkout configuration',{ className: ['wk-alert'] });
			}
		},
		async updateProductSyncRequiredFlag() {
			var response = await apiStore.dispatch("configuration/updateProductSyncRequiredFlag", {shop: this.selectedStore.shop});
			if(response === '') 
			{
				this.$wkToast('Product sync flag updated')
			}
		}
	}
}
</script>